import React from "react";
import { Button } from "react-bootstrap";
import "./styles.css"; // Import the CSS file
import fullstackImage from "./assets/img/fullstack.png";
import bankWithUsimage from "./assets/img/bankwithus.png";
import macbeth1Image from "./assets/img/macbeth1.jpg";
import macbeth2Image from "./assets/img/macbeth2.jpg";
import langlearngerman from "./assets/img/langlearngermanhome.jpg";
import systemArchImage from "./assets/img/system archi.png";
import alexImage from "./assets/img/picOfAlex.png";
import AlexReport from "./assets/pdfs/alex.pdf"; // Import the PDF file

function Projects() {
  const handleViewPdf = () => {
    window.open(AlexReport, "_blank"); // Open the PDF in a new tab
  };

  return (
    <section className="projects-section bg-light" id="projects">
      <div className="container px-4 px-lg-5">
        {/* Project 1: Past Internship */}
        <div className="row gx-0 mb-5 align-items-center">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={fullstackImage}
              alt="Full Stack"
            />
          </div>
          <div className="col-lg-5">
            <div className="featured-text bg-white p-4 rounded shadow text-center text-lg-left">
              <h4>Past Internship</h4>
              <p className="text-black-50 mb-0">
                Full-Stack Developer at{" "}
                <a href="https://www.aastar-trading.com/">Aastar Trading</a>
                <br />I was primarily involved in the development of internal
                website features aimed at improving trading and accounting
                operations. My role encompassed various tasks such as database
                management, the creation of new functions, and conducting
                comprehensive testing for newly implemented features.
              </p>
            </div>
          </div>
        </div>

        {/* Project 2: BankWithUs */}
        <div className="row gx-0 mb-5 align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={bankWithUsimage}
              alt="BankWithUs"
            />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-white p-4 rounded shadow h-100 d-flex flex-column justify-content-center m-2">
              <h4 className="mb-3">BankWithUs</h4>
              <p className="mb-0 text-white-50">
                <a href="https://ay2223s2-cs2113-t13-3.github.io/tp/">
                  CLI OOP Project Done at CS2113
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Project 3: Danjago Project */}
        <div className="row gx-0 mb-5 align-items-center">
          <div className="col-lg-6 order-lg-2 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={langlearngerman}
              alt="LangLearning German"
            />
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="bg-black text-white p-4 rounded shadow h-100 d-flex flex-column justify-content-center m-2">
              <h4 className="mb-3">Danjago Project (Individual)</h4>
              <p className="mb-0 text-white-50">
                Created an interactive web application called{" "}
                <a
                  href="https://langlearngerman.top"
                  className="text-white text-decoration-underline"
                >
                  LangLearning German
                </a>
                , designed to help users learn German vocabulary effectively.
                The app features a dynamic vocabulary list, interactive search
                and filter options, and a user-friendly interface that adapts to
                both desktop and mobile devices.
              </p>
              <p className="mb-0 text-white-50">
                The project was built using modern web technologies including
                React.js for the front-end, Express.js for the back-end, and a
                RESTful API for managing data. It also integrates features like
                user authentication and responsive design to provide a seamless
                learning experience across devices.
              </p>
            </div>
          </div>
        </div>

        {/* Project 4: "Alex to the Rescue" */}
        <div className="row gx-0 mb-5 align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={alexImage}
              alt="Alex to the Rescue"
            />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-white p-4 rounded shadow h-100 d-flex flex-column justify-content-center m-2">
              <h4 className="mb-3">"Alex to the Rescue"</h4>
              <p className="mb-0 text-white-50">
                Alex, our robotic vehicle with search-and-rescue
                functionalities. Done in{" "}
                <a href="https://cfa.nus.edu.sg/naf2023/event/essentially-macbeth/">
                  CG2111A
                </a>
                <br /> With the utilisation of popular hardwares in today's
                search and rescue platforms such as RPLidar, Raspberry Pi,
                Alex's movements can be remotely controlled using secure
                networking, allowing users to view and map the terrain from a
                distance.
                <br />
                <Button
                  variant="default"
                  size="sm"
                  onClick={handleViewPdf}
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  View Report
                </Button>
              </p>
            </div>
          </div>
        </div>

        {/* Project 5: Chinese Drama (CCA) */}
        <div className="row gx-0 mb-5 align-items-center">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={macbeth1Image}
              alt="Macbeth 1"
            />
          </div>
          <div className="col-lg-4">
            <div className="bg-black text-white p-4 rounded shadow h-100 d-flex flex-column justify-content-center m-2">
              <h4 className="mb-3">Chinese Drama (CCA)</h4>
              <p className="mb-0 text-white-50">
                Played in{" "}
                <a href="https://cfa.nus.edu.sg/naf2023/event/essentially-macbeth/">
                  Essentially Macbeth
                </a>{" "}
                at NUS Arts Festival 2023
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              className="img-fluid rounded shadow"
              src={macbeth2Image}
              alt="Macbeth 2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
