import React from "react";
import ReactDOM from "react-dom";
import MyComponent from "./components/MyComponent";
import ReactGA from "react-ga";

const TRACKING_ID = "G-GRVDY0WL52";

ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <MyComponent />
  </React.StrictMode>,
  document.getElementById("root")
);
