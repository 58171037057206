import React, { useEffect, useState } from "react";
import "./styles.css"; // Import the CSS file
import computerImage from "./assets/img/computer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faSun, faCloudRain } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Nav, Col, Button, Modal, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Contact from "./Contact"; // Import the Contact component from the new file
import Projects from "./Projects";
import { apiKey } from "./StaticVariables";

const MyComponent = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showWeatherModal, setShowWeatherModal] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [showForecastModal, setShowForecastModal] = useState(false);
  const [forecastData, setForecastData] = useState(null);

  useEffect(() => {
    // Track the page view when the component is mounted
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  function openWeatherModal() {
    if (!weatherData) {
      const pos = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const crd = pos.coords;

      const fetchWeatherData = async () => {
        const latitude = !crd ? 1.290677 : crd.latitude;
        const longitude = !crd ? 103.852236 : crd.longitude;

        const currentWeatherUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}`;

        try {
          const response = await fetch(currentWeatherUrl);
          const data = await response.json();
          setWeatherData(data);
        } catch (error) {
          console.error("Error fetching weather data:", error);
        }
      };

      const fetchForecastData = async () => {
        try {
          const latitude = !crd ? 1.290677 : crd.latitude;
          const longitude = !crd ? 103.852236 : crd.longitude;
          const forecastUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}`;

          const forecastResponse = await fetch(forecastUrl);
          const forecastData = await forecastResponse.json();
          setForecastData(forecastData);
        } catch (error) {
          console.error("Error fetching forecast data:", error);
        }
      };

      fetchWeatherData();
      fetchForecastData();
    }
    setShowWeatherModal(true);
  }

  const openForecastModal = () => {
    setShowForecastModal(true);
  };

  function Navigation() {
    useEffect(() => {
      const onePageScrollDistance = window.innerHeight * 0.8;
      const handleScroll = () => {
        if (window.scrollY > onePageScrollDistance) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <Navbar
        style={isScrolled ? { backgroundColor: "lightgrey" } : {}}
        bg={!isScrolled ? "" : "lightgrey"}
        expand="lg"
        fixed="top"
      >
        <div className="container px-4 px-lg-5">
          <Navbar.Brand
            onClick={scrollToTop}
            style={{
              fontFamily: "Cursive",
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            Sherlock
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={toggleMenu}
            aria-controls="navbarResponsive"
          />
          <Navbar.Collapse
            id="navbarResponsive"
            className={isMenuOpen ? "show" : ""}
          >
            <Nav className="ms-auto">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#projects">Projects</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
              <Nav.Link onClick={openWeatherModal}>Weather</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>

        <Modal
          show={showWeatherModal}
          onHide={() => setShowWeatherModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Weather Information</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "lightgrey" }}>
            {weatherData ? (
              <>
                <p>
                  Weather:{" "}
                  {weatherData.weather[0].main === "Clouds" && (
                    <FontAwesomeIcon icon={faCloud} />
                  )}
                  {weatherData.weather[0].main === "Clear" && (
                    <FontAwesomeIcon icon={faSun} />
                  )}
                  {weatherData.weather[0].main === "Rain" && (
                    <FontAwesomeIcon icon={faCloudRain} />
                  )}
                  {weatherData.weather[0].main && weatherData.weather[0].main}
                </p>
                <p>
                  Temperature: {Math.round(weatherData.main.temp - 273.15)}°C
                </p>
                <p>Humidity: {weatherData.main.humidity}%</p>
                <p>Description: {weatherData.weather[0].description}</p>
                <p>Wind Speed: {weatherData.wind.speed} m/s</p>
                <p>Cloudiness: {weatherData.clouds.all}%</p>
              </>
            ) : (
              <p>Loading weather data...</p>
            )}
            <Button onClick={() => openForecastModal()}>View Forecast</Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={showForecastModal}
          onHide={() => setShowForecastModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forecast Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {forecastData ? (
              <>
                <Row>
                  <Col>
                    <h4>Today</h4>
                    {forecastData.list
                      .filter((forecastItem) => {
                        const date = new Date(forecastItem.dt_txt);
                        const currentTime = new Date();
                        const today = new Date();
                        const filterEndTime = new Date(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate(),
                          23,
                          59
                        );
                        // Compare the forecast item's time to the current time
                        return date > currentTime && date < filterEndTime;
                      })
                      .map((forecastItem) => (
                        <div key={forecastItem.dt}>
                          <p>Time: {forecastItem.dt_txt.split(" ")[1]}</p>
                          <p>
                            Temperature:{" "}
                            {Math.round(forecastItem.main.temp - 273.15)}°C
                          </p>
                          <p>Weather: {forecastItem.weather[0].main}</p>
                          <br />
                          {/* Add more forecast details as needed */}
                        </div>
                      ))}
                  </Col>
                  <Col>
                    <h4>Tomorrow</h4>
                    {forecastData.list
                      .filter((forecastItem) => {
                        const date = new Date(forecastItem.dt_txt);
                        const tomorrow = new Date();
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        return date.getDate() === tomorrow.getDate();
                      })
                      .map((forecastItem) => (
                        <div key={forecastItem.dt}>
                          <p>Time: {forecastItem.dt_txt.split(" ")[1]}</p>
                          <p>
                            Temperature:{" "}
                            {Math.round(forecastItem.main.temp - 273.15)}°C
                          </p>
                          <p>Weather: {forecastItem.weather[0].main}</p>
                          <br />
                          {/* Add more forecast details as needed */}
                        </div>
                      ))}
                  </Col>
                </Row>
              </>
            ) : (
              <p>Loading forecast data...</p>
            )}
          </Modal.Body>
        </Modal>
      </Navbar>
    );
  }

  function Masthead() {
    return (
      <header className="masthead">
        <link rel="icon" type="image/x-icon" href="http://example.com/favicon.ico" />
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 className="mx-auto my-0 text-uppercase">Yao He</h1>
              <h2 className="text-white-50 mx-auto mt-2 mb-5">
                Current Undergraduate (NUS)
              </h2>

              <Button variant="primary" href="#about">
                Find Out More{" "}
              </Button>
            </div>
          </div>
        </div>
      </header>
    );
  }

  function About() {
    return (
      <section className="about-section text-center" id="about">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8">
              <h2 className="text-white mb-4">
                National University of Singapore
              </h2>
              <p className="text-white-50">
                Computer Engineering Bachelor Degree{" "}
                <a href="https://ceg.nus.edu.sg/">(CEG)</a>
                <br />
                May 2021 - Dec 2024 <br />
              </p>
            </div>
          </div>
          <img className="img-thumbnail" src={computerImage} alt="..." />
        </div>
      </section>
    );
  }

  function Footer() {
    return (
      <footer className="footer bg-black small text-center text-white-50">
        <div className="container px-4 px-lg-5">
          Copyright &copy; Sherlock's Website 2023 |{" "}
          <a
            href="https://www.freeprivacypolicy.com/live/e88ac9d4-77b1-4e2c-8161-76eba1452009"
            style={{ color: "white" }}
          >
            Privacy Policy
          </a>
        </div>
      </footer>
    );
  }

  function App() {
    return (
      <div>
        <Navigation />
        <Masthead />
        <About />
        <Projects />
        <Contact />
        <Footer />
      </div>
    );
  }

  return App();
};

export default MyComponent;
