import React from "react";
import "./styles.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"; // For brand icons

function Contact() {
  return (
    <>
      <div className="shared-background">
        <section className="contact-section" id="contact">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5">
              <div className="col-md-10 col-lg-8 mx-auto text-center">
                <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
                <h2 className="text-white mb-5">Personal Email</h2>

                <div className="row input-group-newsletter">
                  <span className="form-control" id="emailAddress">
                    E0773485@u.nus.edu
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="container px px-4">
            <div className="row gx-4 gx-lg-5">
              <div className="col-md mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-primary mb-2"
                    />
                    <h4 className="text-uppercase m-0">Email</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <a href="mailto:E0773485@u.nus.edu">Drop me an email</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faGithub}
                      className="text-primary mb-2"
                    />
                    <h4 className="text-uppercase m-0">GitHub</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <a
                        href="https://github.com/sherlock-yh"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit my GitHub profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="text-primary mb-2"
                    />
                    <h4 className="text-uppercase m-0">LinkedIn</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <a
                        href="https://www.linkedin.com/in/sherlock-yh "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit my LinkedIn profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;
